import { Component } from '@angular/core';

import { BaseComponent } from '~/pages/base.component';
import { Tab } from '~/components/shared/tabs/tabs.component';
import { SupplyTradingInventoryComponent } from './supplytrading/supplytrading-inventory.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AbuInventoryComponent } from './abu/abu-inventory.component';
import { UserPermissionsService } from '../../services/shared/user-permissions.service';
import { AbuInventory, SupplyTradingInventory } from '~/models/shared/permissions/resource-permission';

@Component({
    selector: 'inventory',
    templateUrl: './inventory.component.html',
    styleUrls: ['./inventory.component.scss']
})

export class InventoryComponent extends BaseComponent {
    public title: string = "Carbon Credit Inventory";
    public error: string = "";

    public callbackLabel: string = "";
    private callback: string = "";
    private callbackParams: any;
    public message: string = "";
    public messageStyle = "";

    private _errorMessageStyle = "color: white; background-color: var(--red);" 

    private _tabs: Tab[] = [
        {
            title: "Supply & Trading",
            type: <any>SupplyTradingInventoryComponent,
            inputs: {
                parentTab: "Supply & Trading"
            },
            resourcePermission: SupplyTradingInventory
        },
        {
            title: "ABU",
            type: <any>AbuInventoryComponent,
            inputs: {
                parentTab: "ABU"
            },
            outputs: {
                'messageEvent': (event: any) => {
                    this.showMessage(event);
                }
            },
            resourcePermission: AbuInventory
        }
    ];

    public tabs: Tab[] = [
    ];

    public activeTab!: Tab | undefined;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private readonly _userPermissionService: UserPermissionsService
    ) {
        super();
    }

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();

        this.setTabPermissions();

        await this.subscribeToRouteParams();

        if (window.history.state.callback) {
            this.callbackLabel = window.history.state.callbackLabel ?? null;
            this.callback = window.history.state.callback ?? null;
            this.callbackParams = window.history.state.callbackParams ?? null;
        }
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public back() {
        this.router.navigate([this.callback], { queryParams: this.callbackParams });
    }

    public confirmTabChange(event: any): Observable<boolean> {
        return new Observable(o => o.next(true));
    }

    public showMessage(event: any) {
        this.message = event;
    }
    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private setTabPermissions() {
        this._userPermissionService.getUserPermissions().subscribe({
            next: (permissions) => {
                this._tabs.forEach(tab => {
                    if (tab.resourcePermission) {
                        if (UserPermissionsService.checkPermission(permissions, tab.resourcePermission, true)) {
                            this.tabs.push(tab);
                        }
                    }
                    else {
                        // no permission restriction specified, grant permission
                        this.tabs.push(tab);
                    }
                });

                if (this.tabs.length > 0 && !this.activeTab) {
                    this.activeTab = this.tabs[0];
                }
            },
            error: (error) => {
                console.error("Error loading permissions: " + error?.message);
            }
        });
    }

    private async subscribeToRouteParams() {
        this.route.queryParams.subscribe((params: any) => {
            if (params?.tab) {
                let tabName = params?.tab;
                if (params?.parentTab) {
                    tabName = params.parentTab;
                }
                this.setActiveTab(tabName);
            }
        });
    }

    private setActiveTab(tabName: string) {
        const tab = this._tabs.find((tab: Tab) => tab.title.startsWith(tabName));
        if (tab) {
            this._userPermissionService.getUserPermissions().subscribe({
                next: (permissions) => {

                    if (tab.resourcePermission) {
                        if (UserPermissionsService.checkPermission(permissions, tab.resourcePermission, true)) {
                            this.activeTab = tab;
                        } else {
                            this.messageStyle = this._errorMessageStyle;
                            this.message = `Access to ${tab.title} Denied`;

                            setTimeout(() => {
                                this.messageStyle = "";
                            }, 5000);
                        }
                    }
                },
                error: (error) => {
                    console.error("Error loading permissions: " + error?.message);
                }
            });
        }
    }
}